import request from '@/utils/request'

// 获取车辆当前状态数据
export function getVehicleStatus(vehicleId) {
  return request({
    url: '/iot/workOrder/get/' + vehicleId,
    method: 'get'
  })
}

// 新增车辆工单信息
export function addWorkOrder(data) {
  return request({
    url: '/iot/workOrder',
    method: 'post',
    data: data
  })
}
// 查询车辆工单信息
export function getWorkOrder(query) {
  return request({
    url: '/iot/workOrder/get',
    method: 'get',
    params: query
  })
}

// 修改车辆工单信息
export function updateWorkOrder(data) {
  return request({
    url: '/iot/workOrder',
    method: 'put',
    data: data
  })
}

// 获取车辆工单信息详细信息
export function getWorkOrderById(id) {
  return request({
    url: '/iot/workOrder/' + id,
    method: 'get'
  })
}

// 查询车辆工单信息列表
export function listWorkOrder(query) {
  return request({
    url: '/iot/workOrder/list',
    method: 'get',
    params: query
  })
}

export function delWorkOrder(id) {
  return request({
    url: '/iot/workOrder/' + id,
    method: 'delete'
  })
}
