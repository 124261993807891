var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "40%",
        height: "auto",
        title: _vm.title,
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        "esc-closable": true,
        position: { top: "10%", left: "30%" },
        remember: "",
        transfer: "",
      },
      on: { close: _vm.handleCloseWorkOrder },
      model: {
        value: _vm.workOrderVisible,
        callback: function ($$v) {
          _vm.workOrderVisible = $$v
        },
        expression: "workOrderVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "work-order-container" },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c("a-form-model-item", { attrs: { label: "工单来源" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.form.orderType === 2 ? _vm.form.createBy : "自动生成"
                    )
                ),
              ]),
              _c("a-form-model-item", { attrs: { label: "车辆编号" } }, [
                _vm._v(" " + _vm._s(_vm.form.vehicleId) + " "),
              ]),
              _c(
                "a-form-model-item",
                { attrs: { label: "生成时间", prop: "createTime" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      "show-time": "",
                      disabled: _vm.form.orderType === 1 || _vm.form.id,
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM-DD HH:mm:ss",
                      "allow-clear": false,
                    },
                    model: {
                      value: _vm.form.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "createTime", $$v)
                      },
                      expression: "form.createTime",
                    },
                  }),
                ],
                1
              ),
              _vm.form.id
                ? _c(
                    "a-form-model-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "处理人", prop: "handler" },
                    },
                    [
                      _c("select-filter", {
                        attrs: {
                          disabled: _vm.isBrowse,
                          "drop-type": "auto",
                          dataSource: _vm.userDataSource,
                        },
                        model: {
                          value: _vm.form.handler,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "handler", $$v)
                          },
                          expression: "form.handler",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.id
                ? _c(
                    "a-form-model-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "处理完成时间", prop: "handleTime" },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          disabled: _vm.isBrowse,
                          "value-format": "YYYY-MM-DD HH:mm:ss",
                          format: "YYYY-MM-DD HH:mm:ss",
                          "allow-clear": false,
                        },
                        model: {
                          value: _vm.form.handleTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "handleTime", $$v)
                          },
                          expression: "form.handleTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { staticClass: "form-item", attrs: { label: "车端运行记录" } },
                _vm._l(
                  Math.ceil(_vm.runningStatus.length / 2),
                  function (row, rIndex) {
                    return _c("div", { key: row, staticClass: "row" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.runningStatus[2 * rIndex].label) + "："
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.runningStatus[2 * rIndex].value) + " "
                          ),
                          _vm.runningStatus[2 * rIndex].withArrow
                            ? _c("img", {
                                ref: "imgRef",
                                refInFor: true,
                                attrs: { src: _vm.arrowUrl },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                      2 * rIndex + 1 < _vm.runningStatus.length
                        ? _c("div", { staticClass: "column" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.runningStatus[2 * rIndex + 1].label
                                ) + "："
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.runningStatus[2 * rIndex + 1].value
                                ) + " "
                              ),
                              _vm.runningStatus[2 * rIndex + 1].withArrow
                                ? _c("img", {
                                    ref: "imgRef",
                                    refInFor: true,
                                    attrs: { src: _vm.arrowUrl },
                                  })
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }
                ),
                0
              ),
              _c("a-form-model-item", {
                staticClass: "form-item",
                attrs: { label: "车端硬件记录" },
              }),
              _c(
                "a-form-model-item",
                { staticClass: "form-item", attrs: { label: "补充说明" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      disabled: _vm.form.id,
                      placeholder: "请输入补充说明",
                      "auto-size": "",
                    },
                    model: {
                      value: _vm.form.moreInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "moreInfo", $$v)
                      },
                      expression: "form.moreInfo",
                    },
                  }),
                ],
                1
              ),
              _vm.form.id
                ? _c(
                    "a-form-model-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "处理情况", prop: "handleState" },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          disabled: _vm.isBrowse,
                          placeholder: "请输入处理情况",
                          "auto-size": "",
                        },
                        model: {
                          value: _vm.form.handleState,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "handleState", $$v)
                          },
                          expression: "form.handleState",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  !_vm.isBrowse
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v(_vm._s(_vm.form.id ? "完成回填" : "生成工单"))]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.workOrderVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }