<template>
  <vxe-modal
    v-model="workOrderVisible"
    width="40%"
    height="auto"
    :title="title"
    @close="handleCloseWorkOrder"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="{ top: '10%', left: '30%' }"
    remember
    transfer
  >
    <div class="work-order-container">
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="工单来源"> {{ form.orderType === 2 ? form.createBy : '自动生成' }}</a-form-model-item>
        <a-form-model-item label="车辆编号">
          {{ form.vehicleId }}
        </a-form-model-item>
        <a-form-model-item label="生成时间" prop="createTime">
          <a-date-picker show-time :disabled="form.orderType === 1 || form.id" v-model="form.createTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" :allow-clear="false" />
        </a-form-model-item>
        <a-form-model-item class="form-item" label="处理人" v-if="form.id" prop="handler">
          <select-filter :disabled="isBrowse" v-model="form.handler" drop-type="auto" :dataSource="userDataSource" />
        </a-form-model-item>
        <a-form-model-item class="form-item" label="处理完成时间" v-if="form.id" prop="handleTime">
          <a-date-picker :disabled="isBrowse" v-model="form.handleTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" :allow-clear="false" />
        </a-form-model-item>
        <a-form-model-item class="form-item" label="车端运行记录">
          <div class="row" v-for="(row, rIndex) in Math.ceil(runningStatus.length / 2)" :key="row">
            <div class="column">
              <span>{{ runningStatus[2 * rIndex].label }}：</span>
              <span>{{ runningStatus[2 * rIndex].value }} <img v-if="runningStatus[2 * rIndex].withArrow" ref="imgRef" :src="arrowUrl" /></span>
            </div>
            <div class="column" v-if="2 * rIndex + 1 < runningStatus.length">
              <span>{{ runningStatus[2 * rIndex + 1].label }}：</span>
              <span>{{ runningStatus[2 * rIndex + 1].value }} <img v-if="runningStatus[2 * rIndex + 1].withArrow" ref="imgRef" :src="arrowUrl" /></span>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item class="form-item" label="车端硬件记录"> </a-form-model-item>
        <a-form-model-item class="form-item" label="补充说明">
          <a-textarea :disabled="form.id" v-model="form.moreInfo" placeholder="请输入补充说明" auto-size />
        </a-form-model-item>
        <a-form-model-item class="form-item" label="处理情况" v-if="form.id" prop="handleState">
          <a-textarea :disabled="isBrowse" v-model="form.handleState" placeholder="请输入处理情况" auto-size />
        </a-form-model-item>
        <div class="btns">
          <a-button v-if="!isBrowse" type="primary" @click="handleSubmit">{{ form.id ? '完成回填' : '生成工单' }}</a-button>
          <a-button @click="workOrderVisible = false">取消</a-button>
        </div>
      </a-form-model>
    </div>
  </vxe-modal>
</template>
<script>
import get from 'lodash/get'
import { getVehicleStatus, addWorkOrder, updateWorkOrder, getWorkOrderById } from '@/api/iot/workOrder'
import { mapState } from 'vuex'
import { parseDateTime } from '@/utils/ruoyi'
import MonitorBase from '@/views/monitor/monitorBase'
import SelectFilter from '../../../SelectFilter'
import { listUser } from '@/api/system/user'
export default {
  mixins: [MonitorBase],
  components: { SelectFilter },
  props: {},
  data() {
    return {
      title: '生成工单',
      workOrderVisible: false,
      form: {
        createBy: '',
        createTime: '',
        moreInfo: '',
        orderType: 2,
        state: 1,
        status: 0,
        vehicleId: '',
        handler: undefined
      },
      rules: {
        createTime: [{ required: true, message: '生成时间不能为空', trigger: 'change' }],
        handler: [{ required: true, message: '处理人不能为空', trigger: 'blur' }],
        handleTime: [{ required: true, message: '处理完成时间不能为空', trigger: 'change' }],
        handleState: [{ required: true, message: '处理情况不能为空', trigger: 'blur' }]
      },
      vehicleStatusJsonStr: null,
      vehicleStatusObj: null,
      vehicleObj: null,
      // 车辆运行状态
      runningStatus: [],
      isBrowse: false,
      userDataSource: []
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info
    })
  },
  created() {
    this.getUserList()
  },
  watch: {},

  methods: {
    reset() {
      this.form = {
        createBy: '',
        createTime: '',
        moreInfo: '',
        orderType: 2,
        state: 1,
        status: 0,
        vehicleId: '',
        handler: undefined,
        handleTime: undefined
      }
    },
    getUserList() {
      listUser({}).then((response) => {
        const rows = response.rows
        if (rows && rows.length > 0) {
          this.userDataSource = rows.map((p) => p.userName)
        }
      })
    },
    getData(vechicleId) {
      if (!vechicleId) {
        return
      }
      getVehicleStatus(vechicleId).then((response) => {
        const data = response.data
        this.vehicleStatusJsonStr = data.vehicleStatus
        this.workOrderVisible = true
        this.setVehicleStatusInfo()
      })
    },
    setVehicleStatusInfo() {
      if (this.vehicleStatusJsonStr) {
        this.vehicleStatusObj = JSON.parse(this.vehicleStatusJsonStr)
        this.runningStatus = []
        const steer = Number(get(this.vehicleStatusObj, 'runningState.steer', 0))
        this.runningStatus.push({
          label: '导向轮方向',
          value: steer < 0 ? '左' + Math.abs(steer).toFixed(2) + '° ' : '右' + Math.abs(steer).toFixed(2) + '° '
        })
        this.runningStatus.push({
          label: '电量',
          value: Number(get(this.vehicleStatusObj, 'runningState.soc', 0)).toFixed(2)
        })
        this.runningStatus.push({
          label: '车头朝向',
          value: Number(get(this.vehicleStatusObj, 'location.heading', 0)).toFixed(2) + '° ',
          withArrow: true
        })
        this.runningStatus.push({
          label: '最近一次上报',
          value: get(this.vehicleStatusObj, 'time', '')
        })
        this.runningStatus.push({
          label: '刹车状态',
          value: '无 '
        })
        // todo...
        if (this.vehicleStatusObj.warnStatus === 3 && this.vehicleStatusObj.managedRequest) {
          this.runningStatus.push({
            label: '请求接管时间',
            value: this.vehicleStatusObj.managedRequest.time
          })
        }
        this.runningStatus.push({
          label: '速度',
          value: Number(get(this.vehicleStatusObj, 'runningState.speed', 0)).toFixed(2) + 'KM/H'
        })
        let status = ''
        if (!this.vehicleObj.online) {
          status = '离线'
        } else if (this.vehicleObj.warnStatus === 2) {
          status = '智驾任务挂起'
        } else if (this.vehicleObj.warnStatus === 3) {
          status = '请求托管'
        }
        this.runningStatus.push({
          label: '智驾状态',
          value: status
        })
      }
      this.$nextTick(() => {
        this.setHeadingArrow(this.vehicleStatusObj, this.$refs.imgRef[0])
      })
    },
    handleCloseWorkOrder() {
      this.workOrderVisible = false
    },
    handleAdd(vehicleObj) {
      this.reset()
      this.vehicleObj = vehicleObj

      this.form.createTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      this.form.vehicleId = vehicleObj.vehicleId
      this.form.createBy = this.userInfo.userName
      this.getData(vehicleObj.vehicleId)
    },
    handleUpdate(orderId, isBrowse) {
      this.reset()
      this.isBrowse = isBrowse
      this.title = isBrowse ? '查看工单' : '回填工单'
      getWorkOrderById(orderId).then((response) => {
        this.form = response.data
        if (!this.form.handleTime && !isBrowse) {
          this.form.handleTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }
        this.form.state = 2
        if (!this.form.handler && !isBrowse) {
          this.form.handler = this.userInfo.userName
        }
        this.vehicleStatusJsonStr = response.workStatus ? response.workStatus.vehicleStatus : ''
        this.vehicleObj = {}
        if (this.vehicleStatusJsonStr) {
          this.vehicleObj = JSON.parse(this.vehicleStatusJsonStr)
        }
        this.setVehicleStatusInfo()
        this.workOrderVisible = true
      })
    },
    handleSubmit() {
      console.log('form', this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.form.id) {
            this.form.vehicleStatus = this.vehicleStatusJsonStr
            addWorkOrder(this.form).then((response) => {
              this.$message.success('生成工单成功', 3)
              this.workOrderVisible = false
            })
          } else {
            if (!this.form.createTime) {
              this.form.createTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }
            updateWorkOrder(this.form).then((response) => {
              this.$message.success('回填工单成功', 3)
              this.$emit('ok')
              this.workOrderVisible = false
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.work-order-container {
  .ant-form-item {
    display: flex;
    margin-bottom: 0;
    .ant-form-item-label {
      flex: 0 0 105px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .btns {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    button:first-child {
      margin-right: 10px;
    }
  }
  .row {
    display: flex;
    line-height: 24px;
    .column {
      flex: 1;
      display: flex;
      align-items: center;
      .direction {
        display: flex;
        align-items: center;
      }
    }
  }
  .form-item {
    margin-top: 10px;
  }
}
</style>
